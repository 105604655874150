<template>
  <!-- site content -->
  <div class="main-page pt-3">
    <main class="main-container">
      <div class="container custom-container">
        <section>
          <div class="row">
            <widget
              :name="translations?.project_info"
              :logo="require('@/assets/img/icons-projects.svg')"
              :open="dashBoardData.project_opened"
              :complete="dashBoardData.project_completed"
              :translations="translations"
            />

            <widget
              :name="translations?.document_info"
              :logo="require('@/assets/img/icons-explorer.svg')"
              :open="dashBoardData.document_opened"
              :complete="dashBoardData.document_completed"
              :translations="translations"
            />

            <widget
              :name="translations?.template_content_item_info"
              :logo="require('@/assets/img/icons-task.svg')"
              :open="totalCi"
              :complete="nullExpCi"
              :translations="translations"
            />
          </div>

          <div class="row mt-3">
            <div
              class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3"
              v-if="
                dashBoardData.document_invitation &&
                dashBoardData.document_invitation.length > 0
              "
            >
              <div class="table-box p-3">
                <div class="">
                  <div class="d-flex">
                    <div class="flex-grow-1">
                      <h2 class="mb-1">{{translations?.document_invitations}}</h2>
                    </div>
                  </div>
                </div>
                <div class="my-3">
                  <div class="table-responsive">
                    <table class="table table-striped table-hover">
                      <thead class="table-header">
                        <tr>
                          <th scope="col"> {{translations?.name}} </th>
                          <th scope="col"> {{translations?.author}} </th>
                          <th scope="col"> {{translations?.role}} </th>
                          <th scope="col"> {{translations?.status}} </th>
                        </tr>
                      </thead>
                      <tbody v-if="dashBoardData.document_invitation">
                        <tr
                          v-for="(
                            doc, index
                          ) in dashBoardData.document_invitation"
                          :key="index"
                        >
                          <td>
                            <div class="text-break">
                              <router-link
                                :to="`/library/update-template/${doc.id}`"
                                >{{ doc.template_name }}</router-link
                              >
                            </div>
                          </td>
                          <td>{{ doc.template_author }}</td>
                          <td>{{ doc.role }}</td>
                          <td>{{ doc.invitation_status }}</td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td colspan="4" class="text-center">
                            {{translations?.no_data_found}} !
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3"
              v-if="
                dashBoardData.project_invaitation &&
                dashBoardData.project_invaitation.length > 0
              "
            >
              <div class="table-box p-3">
                <div class="">
                  <div class="d-flex">
                    <div class="flex-grow-1">
                      <h2 class="mb-1"> {{translations?.project_invitations}} </h2>
                    </div>
                  </div>
                </div>
                <div class="my-3">
                  <div class="table-responsive">
                    <table
                      class="table table-striped table-hover project_invitation"
                    >
                      <thead class="table-header">
                        <tr>
                          <th scope="col"> {{translations?.name}}</th>
                          <th scope="col"> {{translations?.ci}}</th>
                          <th scope="col">{{translations?.project_leader}}</th>
                          <th scope="col"> {{translations?.project_role}}</th>
                          <th scope="col"> {{translations?.date}}</th>
                          <th scope="col"> {{translations?.action}}</th>
                        </tr>
                      </thead>
                      <tbody v-if="dashBoardData.project_invaitation">
                        <tr
                          v-for="(
                            project_invitation, index
                          ) in dashBoardData.project_invaitation"
                          :key="index"
                        >
                          <td>
                            <div class="text-break">
                              <router-link
                                :to="`/projects/${project_invitation.project_id}/project-dashboard`"
                                >{{ project_invitation.serial }} - b</router-link
                              >
                            </div>
                          </td>
                          <td>
                            <router-link :to="`/workspace`">
                              {{
                                getProjectWiseCount(project)
                                  ? getProjectWiseCount(project).total
                                  : "N/A"
                              }}
                            </router-link>
                          </td>
                          <td>{{ project_invitation.project_lead }}</td>
                          <td>{{ project_invitation.role }}</td>
                          <td>{{ project_invitation.start_date }}</td>
                          <td>
                            <span
                              v-if="
                                project_invitation.invitation_status ===
                                'Invited'
                              "
                              class="me-1"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Declined"
                              @click="changeInvitationStatus(index, 'Declined')"
                            >
                              <font-awesome-icon
                                :icon="['fas', `minus-circle`]"
                              ></font-awesome-icon>
                            </span>
                            <span
                              v-if="
                                project_invitation.invitation_status ===
                                'Invited'
                              "
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Accepted"
                              @click="changeInvitationStatus(index, 'Accepted')"
                            >
                              <font-awesome-icon
                                :icon="['fas', `check-circle`]"
                              ></font-awesome-icon>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td colspan="6" class="text-center">
                             {{translations?.project_no_data_found}}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3"
              v-if="
                dashBoardData.is_master === false &&
                dashBoardData.global_documents &&
                dashBoardData.global_documents.length > 0
              "
            >
              <div class="table-box p-3">
                <div class="">
                  <div class="d-flex">
                    <div class="flex-grow-1">
                      <h2 class="mb-1">Global Documents</h2>
                    </div>
                  </div>
                </div>
                <div class="my-3">
                  <div class="table-responsive">
                    <table class="table table-striped table-hover">
                      <thead class="table-header">
                        <tr>
                          <th scope="col"> {{translations?.name}}</th>
                          <th scope="col"> {{translations?.project_status}}</th>
                        </tr>
                      </thead>
                      <tbody v-if="dashBoardData.global_documents">
                        <tr
                          v-for="(doc, index) in dashBoardData.global_documents"
                          :key="index"
                        >
                          <td>
                            <div class="text-break">
                              <router-link
                                :to="`/library/update-template/${doc.id}`"
                                >{{ doc.name }}</router-link
                              >
                            </div>
                          </td>
                          <td>{{ doc.status }}</td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td colspan="4" class="text-center">
                            {{translations?.project_no_data_found}}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <Chart :dashboard-data="dashBoardData" :translations="translations" />
          </div>
        </section>
      </div>
    </main>

    <BottomFooter></BottomFooter>
  </div>
  <!-- /site content end -->
</template>

<script>
import Widget from "@/views/auth/dashboard/Widget";
import { useStore } from "vuex";
import { computed } from "vue";
import HelperFunction from "@/common/helpers";
import BottomFooter from "@/views/layouts/auth/common/BottomFooter";
import Chart from "./components/Charts.vue";

export default {
  name: "Dashboard",
  components: { Widget, BottomFooter, Chart },
  setup() {
    const { storeFormData } = new HelperFunction();
    const store = useStore();
    const dashBoardData = computed(() => store.getters.getDashBoardData);
    const translations = computed(() => {
            return store.state.translations;  
        });
    const totalCi = computed(() => {
      return dashBoardData.template_cis
        ? dashBoardData.template_cis.total_ci
        : 0;
    });
    const nullExpCi = computed(() => {
      return dashBoardData.template_cis
        ? dashBoardData.template_cis.null_exp_ci
        : 0;
    });
    const getProjectWiseCount = (invitation) => {
      let projectWiseCount =
        dashBoardData.pci_count && dashBoardData.pci_count.original
          ? dashBoardData.pci_count.original.data.find((content_count) => {
              return invitation.project_id == content_count.project_id;
            })
          : null;
      return projectWiseCount;
    };
    async function changeInvitationStatus(key, status) {
      const data = {
        invitation_id:
          dashBoardData.value.project_invaitation[key].invitation_id,
        project_id: dashBoardData.value.project_invaitation[key].project_id,
        status: status,
      };
      await storeFormData(
        { url: vueConfig.Dashboard.DashboardInvitationEndPoint },
        data
      ).then((response) => {
        // console.error(response.data.data)
        if (response.data.data != "Declined") {
          const company = store.getters.getSelectedBoard;
          const company_id = {
            company_id: company.id ? company.id : null,
          };
          store.dispatch("getDashboardData", company_id);
          dashBoardData.value.project_invaitation[key].invitation_status =
            response.data;
        } else {
          dashBoardData.value.project_invaitation.splice(key, 1);
        }
      });
    }

    return {
      dashBoardData,
      nullExpCi,
      totalCi,
      getProjectWiseCount,
      changeInvitationStatus,
      translations
    };
  },
};
</script>

<style scoped>
.project_invitation tr td span {
  cursor: pointer;
  font-size: 20px;
}
</style>
