<template>
    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
        <div class="table-box p-3" v-if="dashboardData.templates">
            <DxChart
                id="chart"
                :data-source="dashboardData.templates"
            >
                <DxSeries
                    argument-field="status"
                    value-field="status_count"
                    :name="translations?.documents_status"
                    type="bar"
                    color="#f178a7"
                />
                <DxValueAxis
                    :visible="true"
                >
                </DxValueAxis>
                <DxLegend :visible="true"/>
                <DxTooltip :enabled="true"/>
            </DxChart>
        </div>
    </div>

    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
        <div class="table-box p-3" v-if="dashboardData.tasks">
            <div class="pies-container">
                <DxPieChart
                    id="pie"
                    :data-source="dashboardData.tasks"
                    palette="Bright"
                    :title="translations?.tasks_status"
                >
                    <DxSeries
                        argument-field="status"
                        value-field="status_count"
                    >
                        <DxLabel
                            :visible="true"
                            :customize-text="formatLabel"
                            format="fixedPoint"
                        >
                            <DxConnector
                                :visible="true"
                                :width="0.5"
                            />
                        </DxLabel>
                        <DxSmallValuesGrouping
                            :threshold="4.5"
                            mode="smallValueThreshold"
                        />
                    </DxSeries>
                    <DxLegend
                        horizontal-alignment="center"
                        vertical-alignment="bottom"
                    />
                    <DxExport :enabled="true"/>
                </DxPieChart>
            </div>
        </div>
    </div>
</template>


<script>
import DxChart, {
    DxArgumentAxis,
    DxSeries,
    DxLegend,
    DxTooltip
} from 'devextreme-vue/chart';
import DxPieChart, {
  DxLabel,
} from 'devextreme-vue/pie-chart';
import BottomFooter from "@/views/layouts/auth/common/BottomFooter";
    export default {
        name: "Dashboard",

        props: {
            dashboardData: {
                type: Object,
                required: false
            },
            translations: {
               type: Object,
                required: false 
            }
        },
        components: {DxChart,
        DxArgumentAxis,
        DxSeries,
        DxLegend,
        DxPieChart,
        DxLabel,
        DxTooltip,
        BottomFooter
        }
    }
</script>